import React, { useCallback, useState, useEffect } from "react";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "@emotion/styled";
import {
  IconButton,
  Drawer,
  useScrollTrigger,
  Divider,
} from "@material-ui/core";
import { MenuRounded, MenuOpenRounded } from "@material-ui/icons";
import SelectLanguage from "./SelectLanguage";
import KunanaLogo from "../../images/svg/KunanaLogo.svg";

const WrapperMenuBar = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 1em 1em;
  align-items: center;

  .desktop-menu {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  & a {
    all: unset;
    cursor: pointer;
    font-size: clamp(1.1rem, 2.5vw, 1.1rem);
  }
  .section-mobile {
    display: none;
  }
  @media (max-width: 760px) {
    .desktop-menu {
      display: none;
    }
    .mobile-menu {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding: 1em 1em;
    }
    .section-mobile {
      display: block;
      align-self: center;
    }
  }
`;

const MenuBar = () => {
  const { languages, originalPath, language, changeLanguage } = useI18next();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const trigger = useScrollTrigger();

  const handleWindowResize = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (trigger) {
      setOpen(false);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [handleWindowResize, trigger]);

  const handleLangChange = useCallback(
    (event) => {
      changeLanguage(event.value, originalPath);
    },
    [changeLanguage, originalPath]
  );

  const MenuMobile = (
    <div
      style={{
        padding: "10px 10px",
        width: 200,
        height: "100%",
      }}
    >
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          setOpen(false);
        }}
      >
        <MenuOpenRounded />
      </IconButton>

      <div className="mobile-menu">
        <Link to="/">{t("Home")}</Link>
        <Link to="/#portfolio">{t("Portfolio")}</Link>
        <Link to="/#company">{t("Company")}</Link>
        <Link to="/#contact">{t("Contact us")}</Link>
        <Divider style={{ margin: "3px 0px", width: "100%" }} />
        <SelectLanguage
          languages={languages}
          currency={language}
          handleLangChange={handleLangChange}
        />
      </div>
    </div>
  );

  return (
    <WrapperMenuBar>
      <Link aria-label="kunana-link" to="/">
        <KunanaLogo />
      </Link>
      <div className="desktop-menu">
        <Link to="/">{t("Home")}</Link>
        <Link to="/#portfolio">{t("Portfolio")}</Link>
        <Link to="/#company">{t("Company")}</Link>
        <Link to="/#contact">{t("Contact us")}</Link>

        <SelectLanguage
          languages={languages}
          currency={language}
          handleLangChange={handleLangChange}
        />
      </div>

      <div className="section-mobile">
        <IconButton
          aria-label="delete"
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          <MenuRounded />
        </IconButton>
      </div>
      <Drawer
        variant="persistent"
        anchor={"right"}
        open={open}
        style={{
          position: "absolute",
        }}
      >
        {MenuMobile}
      </Drawer>
    </WrapperMenuBar>
  );
};

export default MenuBar;
